/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BillingCallbackPaymentMethodAttachedRequestBody,
  BillingCard,
  BillingCardId,
  BillingConfig,
  BillingMethodId,
  BillingTransaction,
  CustomerBillingResponse,
  StripeClientSecretResponseBody,
} from '@shared/types/billing';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BillingService {
  constructor(private http: HttpClient) {}

  getBillingHistory(): Observable<BillingTransaction[]> {
    return this.http.get<BillingTransaction[]>(`/api/v1/billing/payments`, {
      params: { nebula: 'true' },
    });
  }

  getCards(): Observable<BillingCard[]> {
    return this.http.get<BillingCard[]>(`/api/v1/billing/cards`);
  }

  removeCard(id: BillingCardId): Observable<void> {
    return this.http.delete<void>(`/api/v1/billing/cards/${id}`);
  }

  getBillingConfig() {
    return this.http.get<BillingConfig>(`/api/v1/billing/config`);
  }

  getClientSecretKey() {
    return this.http.post<StripeClientSecretResponseBody>(`/api/v1/billing/cards`, {});
  }

  getCustomerBilling(): Observable<CustomerBillingResponse> {
    return this.http.get<CustomerBillingResponse>('/api/v1/billing/customer');
  }

  setDefaultBillingMethod(billingMethodId: BillingMethodId) {
    const body: BillingCallbackPaymentMethodAttachedRequestBody = {
      type: 'payment_method.attached',
      data: {
        object: {
          id: billingMethodId,
        },
      },
    };
    return this.http.post('/api/v1/billing/callbacks', body);
  }
}
